<template>
  <input
    type="date"
    v-model="currentDate"
    required
    class="form-control"
    :name="id"
    :id="id"
    :min="formattedMin"
    :max="formattedMax"
    @update:model-value="onUpdate"
  />
</template>

<script>
import lodash from 'lodash'
import { DateTime } from 'luxon'

export default {
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: DateTime,
      required: false,
      default: () => null,
    },
    id: {
      type: String,
      required: false,
    },
    max: {
      type: DateTime,
      required: false,
      default: () => null,
    },
    min: {
      type: DateTime,
      required: false,
      default: () => null,
    },
  },
  data() {
    return {
      currentDate: '',
    }
  },
  computed: {
    formattedMin() {
      return this.min ? this.min.toFormat('yyyy-LL-dd') : null;
    },
    formattedMax() {
      return this.max ? this.max.toFormat('yyyy-LL-dd') : null;
    }
  },
  watch: {
    modelValue(newVal) {
      if (newVal) {
        this.currentDate = newVal.toFormat('yyyy-LL-dd');
      } else {
        this.currentDate = DateTime.now().toFormat('yyyy-LL-dd');
      }
    }
  },
  methods: {
    onUpdate(newValue) {
      this.currentDate = newValue;
      this.emitUpdate();
    },
    emitUpdate: lodash.debounce(function () {
      this.$emit(
        'update:modelValue',
        DateTime.fromFormat(this.currentDate, 'yyyy-LL-dd')
      );
    }, 300),
  },
  mounted() {
    if (this.modelValue) {
      this.currentDate = this.modelValue.toFormat('yyyy-LL-dd');
    } else {
      this.currentDate = DateTime.now().toFormat('yyyy-LL-dd');
    }
  }
}
</script>
