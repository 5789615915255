import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/stores/user'
import { useCountryStore } from '@/stores/country'
import { useLanguageStore } from '@/stores/language'
import { usePriceTypeStore } from '@/stores/priceType'
import { useAppInsights } from '@/composables/appInsights'
import { useDevice } from '@/composables/device'

function buildAppInsights(instrumentationKey, initializer) {
  if (!instrumentationKey) {
    console.debug('Unable to start App insights. No instrumentationKey given')
    return
  }

  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey,
      autoTrackPageVisitTime: true,
      enableAutoRouteTracking: true,
    },
  })
  appInsights.loadAppInsights()
  appInsights.addTelemetryInitializer(initializer)

  return appInsights
}

function getCurrentRouteName() {
  const router = useRouter()

  const path = window.location.pathname.replace(router.options.base, '')
  const route = router.matcher.match(path)

  if (route.name) {
    return route.name
  }

  return path.replace(/[^a-z0-9]/gi, '')
}

export const appInsights = {
  install: (app, config) => {
    const appInsightsComposable = useAppInsights()

    const initializer = (envelope) => {
      // Ignore all api requests
      if (envelope.baseType === 'RemoteDependencyData') {
        return false
      }

      // Change the page name to the name of the view route
      if (envelope.baseType === 'PageviewData') {
        envelope.baseData.name = getCurrentRouteName()
      }

      const userStore = useUserStore()
      const countryStore = useCountryStore()
      const languageStore = useLanguageStore()
      const priceTypeStore = usePriceTypeStore()
      const device = useDevice()

      envelope.baseData.properties = {
        ...envelope.baseData.properties,
        authLevel: userStore.isAuthenticated ? 'loggedIn' : 'guest',
        country: countryStore.country.code,
        language: languageStore.language.code,
        priceType: priceTypeStore.priceType.value,
        isPWA: device.isPWA(),
      }
    }

    const instance = buildAppInsights(config.instrumentationKey, initializer)

    if (instance) {
      appInsightsComposable.setAppInsights(instance)
    }
  },
}
