<template>
  <div v-if="visible">
    <div class="pricingTitle">
      <router-link
        class="float-right py-0 m-1 btn btn-primary"
        :to="{
          name: 'FuturePriceMatrix',
          params: {
            util,
          },
          query: {
            dateSetter: priceDateSetter,
          },
        }"
      >
        {{ $t('component.futuretable.all prices') }}
      </router-link>

      <h3>
        <utility-icon :util="util"></utility-icon>
        <span>{{ marketName }} {{ utilName }}</span>
      </h3>
    </div>

    <loadable-table
      :loading="loaderData"
      :error="error"
      :expected-rows="relativePeriod.length"
      :table-class="tableClass"
      :items="loaderData ? [] : priceData"
    >
      <template #head>
        <thead>
          <tr>
            <th scope="col">{{ $t('component.futuretable.product') }}</th>
            <th
              scope="col"
              :colspan="util === 'e' ? 1 : 2"
              v-if="convertsPrice"
              v-tooltip:top.html="baseTooltip"
            >
              {{ $t('component.futuretable.basem3') }}
              <i class="fas fa-info-circle"></i>
            </th>
            <th
              scope="col"
              :colspan="util === 'e' ? 1 : 2"
              v-tooltip:top.html="baseTooltip"
              v-else
            >
              {{ $t('component.futuretable.base') }}
              <i class="fas fa-info-circle"></i>
            </th>
            <th
              scope="col"
              v-if="util === 'e' && countryStore.country !== 'be'"
              v-tooltip:top.html="peakTooltip"
            >
              {{ $t('component.futuretable.peak') }}
              <i class="fas fa-info-circle"></i>
            </th>
            <th
              scope="col"
              v-else-if="util === 'e' && countryStore.country === 'be'"
            >
              {{ $t('component.futuretable.peak') }}
            </th>
            <th scope="col" v-if="util === 'e'">
              {{ $t('component.futuretable.offpeak') }}
            </th>
            <th scope="col" v-if="priceData.length !== 0" class="text-center">
              {{
                DateTime.fromFormat(priceData[0].date, 'yyyyLLdd').toFormat(
                  'dd-LL',
                )
              }}
            </th>
          </tr>
        </thead>
      </template>
      <template #row="{ item }">
        <td>
          <div :class="indicationClass(item, item.base)">
            {{ item.stripname }}
          </div>
        </td>

        <td>
          {{
            convertsPrice
              ? formatter.volume(item.base)
              : formatter.decimal(item.base)
          }}
        </td>

        <td v-if="util === 'e'">
          {{
            convertsPrice
              ? formatter.volume(item.peak)
              : formatter.decimal(item.peak)
          }}
        </td>

        <td v-if="util === 'e'">
          {{
            convertsPrice
              ? formatter.volume(item.offpeak)
              : formatter.decimal(item.offpeak, item.montelprice === 1)
          }}
        </td>
        <template v-else-if="util === 'g'">
          <td></td>
        </template>

        <td class="text-center">
          <indicator-icon :preset="item.baseposition" />
          {{ formatter.decimal(item.basediff) }}
        </td>
        <td class="text-right">
          <router-link
            :to="{
              name: 'FuturePriceGraphProduct',
              params: {
                product: item.stripname,
                util,
              },
            }"
          >
            <i class="fas fa-chart-line" aria-hidden="true"></i>
          </router-link>
        </td>
      </template>
    </loadable-table>

    <p class="indicative-description" v-if="priceHasIndicative">
      {{ $t('general.indicativeprices') }}
    </p>

    <div
      class="otc-years-description"
      v-if="hasOTCYears"
      v-html="$t('component.futuretable.otc-clarification')"
    ></div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { formatter } from '@/formatter'

import { useCountryStore } from '@/stores/country'
import { usePriceTypeStore } from '@/stores/priceType'
import { useMobileUtilityStore } from '@/stores/mobileUtility'

import { useDevice } from '@/composables/device'

import IndicatorIcon from '@/components/common/IndicatorIcon.vue'
import UtilityIcon from '@/components/common/UtilityIcon.vue'
import LoadableTable from '@/components/common/LoadableTable.vue'

export default {
  components: {
    IndicatorIcon,
    UtilityIcon,
    LoadableTable,
  },
  props: ['util', 'calendar', 'latestPrice', 'priceLoader', 'error'],
  data() {
    return {
      searchPhrase: '',
      priceCompare: '',
      loading: true,
      option: {},
    }
  },
  setup() {
    const device = useDevice()
    const priceTypeStore = usePriceTypeStore()
    const countryStore = useCountryStore()
    const mobileUtilityStore = useMobileUtilityStore()

    return {
      device,
      countryStore,
      priceTypeStore,
      mobileUtilityStore,
      formatter,
      DateTime,
    }
  },
  computed: {
    marketName() {
      return (
        this.$t('general.market.future') +
        ' ' +
        this.countryStore.country?.code.toUpperCase()
      )
    },
    priceData() {
      if (this.latestPrice) {
        return this.latestPrice
          .filter((item) => {
            return this.relativePeriod.includes(item.relativeperiod.toString())
          })
          .filter(
            (item) => item.pricetype === this.priceTypeStore.priceType.value,
          )
      }
      return []
    },
    priceHasIndicative() {
      return (
        this.priceData.filter((item) => {
          return item.montelprice === 1
        }).length > 0
      )
    },
    hasOTCYears() {
      return (
        this.priceData.filter((item) => {
          return this.isOTCYear(item)
        }).length > 0
      )
    },
    priceDateSetter() {
      if (this.priceData && this.priceData.length > 0) {
        return this.priceData[0].date
      }
      return DateTime.now().minus({ days: 1 }).toFormat('yLLdd')
    },
    loaderData() {
      return this.priceLoader
    },
    utilName() {
      return this.$t('general.utilitytype.' + this.util)
    },
    tableClass() {
      if (this.util === 'e') {
        return (
          'table table-striped eTable ' +
          (this.priceHasIndicative ? 'table-unpad' : '')
        )
      }
      return (
        'table table-striped gTable ' +
        (this.priceHasIndicative ? 'table-unpad' : '')
      )
    },
    convertsPrice() {
      return this.countryStore.country.code === 'nl' && this.util === 'g'
    },
    relativePeriod() {
      if (
        this.util === 'g' &&
        (this.countryStore.country.code === 'nl' ||
          this.countryStore.country.code === 'be')
      ) { return ['1', '2', '3', '4', '5'] }
      if (
        this.util === 'g' &&
        (this.countryStore.country.code === 'de' ||
          this.countryStore.country.code === 'at')
      ) { return ['1', '2', '3', '4'] }
      if (this.util === 'e' && this.countryStore.country.code === 'nl') { return ['1', '2', '3', '4'] }
      if (this.util === 'e' && this.countryStore.country.code === 'be') { return ['1', '2', '3'] }
      if (
        this.util === 'e' &&
        (this.countryStore.country.code === 'de' ||
          this.countryStore.country.code === 'at')
      ) { return ['1', '2', '3', '4', '5'] }
      return []
    },
    marketCode() {
      if (
        this.countryStore.country.code === 'nl' ||
        this.countryStore.country.code === 'be'
      ) { return 'endex' }

      if (this.countryStore.country.code === 'de') return 'eex'

      return ''
    },
    visible() {
      if (!this.device.isMobile()) {
        return true
      }

      if (this.$route.name !== 'Home') {
        return false
      }

      if (this.mobileUtilityStore.shortUtility !== this.util) {
        return false
      }

      return true
    },
    baseTooltip() {
      if (this.util === 'e') {
        return this.$t(
          `general.marketNames.e.${this.countryStore.country.code}.future.base`,
        )
      }

      return this.$t(
        `general.marketNames.g.${this.countryStore.country.code}.future`,
      )
    },
    peakTooltip() {
      if (this.util === 'e') {
        return this.$t(
          `general.marketNames.e.${this.countryStore.country.code}.future.peak`,
        )
      }

      return this.$t(
        `general.marketNames.g.${this.countryStore.country.code}.future`,
      )
    },
  },
  methods: {
    isOTCYear(item) {
      const year = parseInt(item.stripname.split('-')[1])
      const currentYear = parseInt(DateTime.now().toFormat('yy'))

      return (
        currentYear + 4 === year ||
        currentYear + 5 === year ||
        currentYear + 6 === year ||
        currentYear + 7 === year
      )
    },
    indicationClass(item, price) {
      if (parseFloat(price) === 0) return ''

      const isOtcPrice = this.isOTCYear(item)
      const isMontelPrice = item.montelprice === 1
      if (isOtcPrice && isMontelPrice) return 'otc-montel-price'

      return isOtcPrice ? 'otc-price' : isMontelPrice ? 'montel-price' : ''
    },
  },
}
</script>
<style scoped lang="scss">
.fa-info-circle {
  font-size: 11px;
}

.indicative-description {
  font-size: 11px;
  color: #adb5bd;
}

.otc-price {
  &:after {
    content: '¹';
  }
}

.otc-montel-price {
  &:after {
    content: '¹*';
  }
}

.montel-price:after {
  content: '*';
}

.otc-years-description {
  font-size: 11px;
  color: #adb5bd;
  margin-bottom: 15px;
}
</style>
