export default {
  mounted: function bsTooltipCreate(el, binding) {
    const options = {
      title: binding.value,
      placement: 'top',
      html: false,
    }
    if (binding.modifiers.html) {
      options.html = true
    }
    $(el).tooltip(options)
  },
  updated: function bsTooltipUpdate(el, binding) {
    const options = {
      title: binding.value,
      placement: 'top',
      html: false,
    }
    if (binding.modifiers.html) {
      options.html = true
    }
    $(el).tooltip('dispose').tooltip(options)
  },
  unmounted(el, binding) {
    $(el).tooltip('dispose')
  },
}
