import { computed, ref } from 'vue'
import { useLanguageStore } from '@/stores/language'
import { useCountryStore } from '@/stores/country'
import { useDataAgeStore } from '@/stores/dataAge'
import axios from '@/axios'

export const useSharedBlogStore = (frontend) => {
  const languageStore = useLanguageStore()
  const dataAgeStore = useDataAgeStore()
  const post = ref(null)
  const unread = ref(null)
  const posts = ref([])
  const pagination = ref({
    current_page: 1,
  })
  const searchPhrase = ref('')

  async function fetchPosts(page = null) {
    const params = {
      order: ['created_at:desc'],
      query: searchPhrase.value,
      cacheKey: dataAgeStore.age,
      page: page !== null ? page : pagination.value.current_page,
    }
    
    if (frontend) {
      params.country = useCountryStore().country.code
      params.frontend = true
    }

    try {
      const response = await axios.get('/api/blog', {
        params,
      })

      unread.value = response.data.unread
      posts.value = response.data.data
      pagination.value = response.data.pagination
  
      return posts.value
    } catch (error) {
      return null;
    }
  }

  async function fetchPost(id = null) {
    if (id === null && post.value !== null) {
      id = post.value.blogkey
    }

    const response = await axios.get(`/api/blog/${id}`)

    post.value = response.data.data
    return response.data.data
  }

  function translateProperty(property) {
    const languageCode = languageStore.language.code

    if (typeof property[languageCode] !== 'undefined') {
      return property[languageCode]
    }

    return property[[Object.keys(property)[0]]]
  }

  function translatePost(post) {
    return {
      ...post,
      name: translateProperty(post.name),
      content: translateProperty(post.content),
    }
  }

  const translatedPost = computed(() => {
    if (post.value === null) {
      return null
    }

    return translatePost(post.value)
  })

  const translatedPosts = computed(() => {
    return posts.value.map((post) => {
      return translatePost(post)
    })
  })

  return {
    post,
    posts,
    unread,
    translatedPost,
    translatedPosts,
    pagination,
    searchPhrase,
    fetchPost,
    fetchPosts,
  }
}
