import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useAreaStore = defineStore('area', () => {
  const areas = ref([
    {
      name: 'GPL',
      value: '1',
      countries: ['de'],
      isDefaultInCountries: [],
    },
    {
      name: 'NCG',
      value: '2',
      countries: ['de'],
      isDefaultInCountries: [],
    },
    {
      name: 'THE',
      value: '3',
      countries: ['de', 'at'],
      isDefaultInCountries: ['de'],
    },
    {
      name: 'CEGH',
      value: '-1',
      countries: ['at'],
      isDefaultInCountries: ['at'],
    },
  ])

  return {
    areas,
  }
})
