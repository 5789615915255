import { defineStore } from 'pinia'
import { useSharedBlogStore } from '@/composables/useSharedBlogStore'
import axios from 'axios'
import { computed } from 'vue'

export const useBlogStore = defineStore('blog', () => {
  const sharedBlogStore = useSharedBlogStore(true)

  function find(id) {
    return sharedBlogStore.posts.value.find(
      (post) => post.blogkey === parseInt(id),
    )
  }

  async function refetch() {
    const promises = []
    if (sharedBlogStore.posts.length > 0) {
      promises.push(sharedBlogStore.fetchPosts())
    }

    if (sharedBlogStore.post.value !== null) {
      promises.push(sharedBlogStore.fetchPost())
    }

    return Promise.all(promises)
  }

  async function preloadPost(id) {
    const preloadedPost = find(id)

    if (typeof preloadedPost !== 'undefined') {
      sharedBlogStore.post.value = preloadedPost
    }
  }

  async function markVisit(id) {
    const post = find(id)
    const postIndex = sharedBlogStore.posts.value.indexOf(post)
    sharedBlogStore.posts.value[postIndex] = {
      ...post,
      visited: true,
    }

    return axios.post(`/api/blog/${id}/mark-visit`).then(
      () => sharedBlogStore.unread.value--
    )
  }

  const unvisitedPostCount = sharedBlogStore.unread

  return {
    ...sharedBlogStore,
    refetch,
    preloadPost,
    markVisit,
    unvisitedPostCount,
  }
})
