import { DateTime } from 'luxon'

export default {
  xAxis: {
    type: 'datetime',
    gridLineWidth: 1,
    tickPositioner: undefined,
    labels: {
      formatter() {
        const date = DateTime.fromMillis(this.value)
        switch (this.dateTimeLabelFormat) {
          case 'week':
            return date.toFormat('d-L')
          case 'day':
            return date.toFormat('d LLL')
          case 'month':
            return date.toFormat('LLL yyyy')
          case 'year':
            return date.toFormat('yyyy')
        }
      },
      style: {
        fontSize: '14px',
      },
    },
    dateTimeLabelFormats: {
      day: 'day',
      week: 'week',
      month: 'month',
      year: 'year',
    },
  },
  legend: {
    y: 10,
  },
}
