<template>
  <div class="row m-0">
    <section
      class="col-xs-12 col-sm-12 col-md-4 content-border-right setFullHeight"
    >
      <div class="row">
        <div class="container-fluid mt-lg-4 mt-md-2">
          <div v-if="showPrices">
            <future-price-table
              util="e"
              :latest-price="fPriceE"
              :price-loader="isLoading.futureE"
              :error="hasError.futureE"
            ></future-price-table>
            <spot-price-table
              util="e"
              :year-price="sPriceEYear"
              :month-price="sPriceEMonth"
              :price-loader="isLoading.spotE"
              :error="hasError.spotE"
            ></spot-price-table>
            <future-price-table
              util="g"
              :latest-price="fPriceG"
              :price-loader="isLoading.futureG"
              @charea="setFutureArea"
              :error="hasError.futureG"
            ></future-price-table>
            <spot-price-table
              util="g"
              :year-price="sPriceGYear"
              :month-price="sPriceGMonth"
              :price-loader="isLoading.spotG"
              :error="hasError.spotG"
            ></spot-price-table>
            <fuel-price-table
              :latest-price="PriceFuel"
              :price-loader="isLoading.fuel"
              :error="hasError.fuel"
            ></fuel-price-table>
          </div>
        </div>
      </div>
    </section>

    <section
      class="col-xs-12 col-sm-12 col-md-8 setFullHeight backgroundRightCol"
    >
      <div class="row">
        <div class="container-fluid mt-lg-4 mt-md-2">
          <router-view v-slot="{ Component }">
            <transition name="slide-up">
              <component :is="Component" />
            </transition>
          </router-view>
        </div>
      </div>
    </section>

    <mobile-navigation
      v-if="userStore.isAuthenticated"
      :alertsOn="userStore.alertsEnabled"
      :guest="false"
    ></mobile-navigation>
    <mobile-navigation v-else :guest="true"></mobile-navigation>
  </div>
</template>

<script>
import { useCountryStore } from '@/stores/country'
import { useLatestPriceStore } from '@/stores/latestPrice'
import { useDataAgeStore } from '@/stores/dataAge'
import { useUserStore } from '@/stores/user'
import { useBlogStore } from '@/stores/blog'

import FuturePriceTable from '@/components/pricetables/FuturePriceTable.vue'
import SpotPriceTable from '@/components/pricetables/SpotPriceTable.vue'
import FuelPriceTable from '@/components/pricetables/FuelPriceTable.vue'
import MobileNavigation from '@/components/general/MobileNavigation.vue'

export default {
  components: {
    FuturePriceTable,
    SpotPriceTable,
    FuelPriceTable,
    MobileNavigation,
  },
  data() {
    return {
      showPrices: true,
      futureArea: 3,
      spotArea: 3,
      searchParameters: {
        start: null,
        end: null,
        pricetype: null,
        utilitytype: null,
        country: null,
        period: null,
        area: null,
        order: ['stripname:asc'],
      },
    }
  },
  setup() {
    const latestPriceStore = useLatestPriceStore()
    const countryStore = useCountryStore()
    const dataAgeStore = useDataAgeStore()
    const userStore = useUserStore()
    const blogStore = useBlogStore()

    return {
      countryStore,
      dataAgeStore,
      latestPriceStore,
      userStore,
      blogStore,
    }
  },
  async mounted() {
    await this.dataAgeStore.checkForUpdates()
    await this.blogStore.fetchPosts()

    return this.latestPriceStore.fetchPrices(this.searchParameters)
  },
  methods: {
    setFutureArea(event) {
      this.futureArea = parseInt(event.target.value)
    },
  },
  computed: {
    hasError() {
      return {
        futureE: !this.latestPriceStore.isLoading && this.fPriceE.length === 0,
        futureG: !this.latestPriceStore.isLoading && this.fPriceG.length === 0,
        spotE:
          !this.latestPriceStore.isLoading &&
          this.sPriceEYear.length === 0 &&
          this.sPriceEMonth.length === 0,
        spotG:
          !this.latestPriceStore.isLoading &&
          this.sPriceGYear.length === 0 &&
          this.sPriceGMonth.length === 0,
        fuel:
          !this.latestPriceStore.isLoading &&
          this.latestPriceStore.fuelPrices.length === 0,
      }
    },
    isLoading() {
      return {
        futureE: this.latestPriceStore.isLoading && this.fPriceE.length === 0,
        futureG: this.latestPriceStore.isLoading && this.fPriceG.length === 0,
        spotE: this.latestPriceStore.isLoading && this.sPriceEYear.length === 0,
        spotG: this.latestPriceStore.isLoading && this.sPriceGYear.length === 0,
        fuel:
          this.latestPriceStore.isLoading &&
          this.latestPriceStore.fuelPrices.length === 0,
      }
    },
    spArea() {
      if (
        this.countryStore.country.code === 'nl' ||
        this.countryStore.country.code === 'be' ||
        this.countryStore.country.code === 'at'
      ) {
        return -1
      }
      return this.spotArea
    },
    fuArea() {
      if (
        this.countryStore.country.code === 'nl' ||
        this.countryStore.country.code === 'be' ||
        this.countryStore.country.code === 'at'
      ) {
        return -1
      }
      return this.futureArea
    },
    futureELoader() {
      return this.latestPriceStore.isLoading && this.fPriceE.length === 0
    },
    futureGLoader() {
      return this.latestPriceStore.isLoading && this.fPriceG.length === 0
    },
    SpotELoader() {
      return this.latestPriceStore.isLoading && this.sPriceEYear.length === 0
    },
    SpotGLoader() {
      return this.latestPriceStore.isLoading && this.sPriceGYear.length === 0
    },
    FuelLoader() {
      return (
        this.latestPriceStore.isLoading &&
        this.latestPriceStore.priceFuel.length === 0
      )
    },
    fPriceE() {
      if (this.latestPriceStore.futureElectricityPrices.length !== 0) {
        return this.latestPriceStore.futureElectricityPrices.filter(
          (price) =>
            price.countrycode ===
              this.countryStore.country.code.toUpperCase() &&
            price.calendar === 12,
        )
      }
      return []
    },
    fPriceG() {
      if (this.latestPriceStore.futureGasPrices.length !== 0) {
        const countryCode =
          this.countryStore.country.code === 'be'
            ? 'nl'
            : this.countryStore.country.code
        return this.latestPriceStore.futureGasPrices.filter(
          (price) =>
            price.countrycode === countryCode.toUpperCase() &&
            price.area === this.fuArea &&
            price.calendar === -1,
        )
      }
      return []
    },
    sPriceEYear() {
      if (this.latestPriceStore.spotElectricityYearPrices.length !== 0) {
        let calendar = 12
        if (this.countryStore.country.code === 'nl') {
          calendar = 16
        }
        return this.latestPriceStore.spotElectricityYearPrices.filter(
          (price) =>
            price.countrycode ===
              this.countryStore.country.code.toUpperCase() &&
            price.calendar === calendar,
        )
      }
      return []
    },
    sPriceGYear() {
      if (this.latestPriceStore.spotGasYearPrices.length !== 0) {
        const countryCode =
          this.countryStore.country.code === 'be'
            ? 'nl'
            : this.countryStore.country.code
        return this.latestPriceStore.spotGasYearPrices.filter(
          (price) =>
            price.countrycode === countryCode.toUpperCase() &&
            price.area === this.spArea &&
            price.calendar === -1,
        )
      }
      return []
    },
    sPriceEMonth() {
      if (this.latestPriceStore.spotElectricityMonthPrices.length !== 0) {
        let calendar = 12
        if (this.countryStore.country.code === 'nl') {
          calendar = 16
        }
        return this.latestPriceStore.spotElectricityMonthPrices.filter(
          (price) =>
            price.countrycode ===
              this.countryStore.country.code.toUpperCase() &&
            price.calendar === calendar,
        )
      }
      return []
    },
    sPriceGMonth() {
      if (this.latestPriceStore.spotGasMonthPrices.length !== 0) {
        const countryCode =
          this.countryStore.country.code === 'be'
            ? 'nl'
            : this.countryStore.country.code
        return this.latestPriceStore.spotGasMonthPrices.filter(
          (price) =>
            price.countrycode === countryCode.toUpperCase() &&
            price.area === this.spArea &&
            price.calendar === -1,
        )
      }
      return []
    },
    PriceFuel() {
      if (this.latestPriceStore.fuelPrices.length !== 0) {
        return this.latestPriceStore.fuelPrices.filter(
          (price) => price.calendar === -1,
        )
      }
      return []
    },
  },
}
</script>
