import { defineStore } from 'pinia'
import { onBeforeUnmount, onMounted, ref } from 'vue'
import { useDevice } from '@/composables/device'
import { useRefreshEvent } from '@/composables/events/refreshEvent'
import axios from '@/axios'

const CHECK_INTERVAL = 30000

export const useDataAgeStore = defineStore('data-age', () => {
  const device = useDevice()
  const refreshEvent = useRefreshEvent()

  const age = ref(0)

  let interval = null

  function checkForUpdates() {
    if (device.isOffline()) {
      return
    }

    return axios
      .get('/api/checkAge')
      .then((response) => {
        if (age.value > response.data.data.epoch) {
          return
        }

        const previousDataAge = age.value
        age.value = response.data.data.epoch

        if (previousDataAge === 0) {
          setCheckInterval()
          return
        }

        refreshEvent.emit(age.value, previousDataAge)
      })
      .catch(() => {
        // ignore
      })
  }

  function setCheckInterval() {
    if (interval) {
      clearInterval(interval)
    }

    interval = setInterval(() => checkForUpdates(), CHECK_INTERVAL)
  }

  onBeforeUnmount(() => {
    clearInterval(interval)
  })

  return {
    age,
    checkForUpdates,
  }
})
