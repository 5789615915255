<template>
  <div>
    <div class="row" v-if="blogStore.post?.blogkey === blogId">
      <div class="col-md-12" id="blogdetail">
        <div class="setPadding">
          <router-link
            :to="{ name: 'Home' }"
            class="btn btn-default d-none d-md-inline-block px-1 pt-0 float-right"
          >
            <i class="fas fa-arrow-circle-left"></i>
            {{ $t('pages.blog.backtooverview') }}
          </router-link>
          <h1>{{ blogStore.translatedPost.name }}</h1>

          <div v-html="blogStore.translatedPost.content"></div>
        </div>
      </div>

      <div class="col-md-12 blogmetadata">
        <p>
          {{ $t('pages.blog.placed_at') }}
          {{
            localizedLuxon.fromISOToFullLocaleString(blogStore.post.created_at)
          }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { useBlogStore } from '@/stores/blog'
import { useLocalizedLuxon } from '@/composables/localizedLuxon'

export default {
  data() {
    return {
      blogId: parseInt(this.$route.params.id),
    }
  },
  setup() {
    const blogStore = useBlogStore()
    const localizedLuxon = useLocalizedLuxon()

    return { blogStore, localizedLuxon }
  },
  mounted() {
    this.blogStore.preloadPost(this.blogId)
    this.blogStore.fetchPost(this.blogId)
    this.blogStore.markVisit(this.blogId)

    window.scrollTo(0, 0)
  },
}
</script>
<style>
#blogdetail img {
  max-width: 100%;
  height: auto;
}
</style>
