<template>
  <div>
    <router-link
      :to="{ name: 'Home' }"
      class="btn btn-default d-none px-1 d-md-inline-block float-right"
    >
      <i class="fas fa-arrow-circle-left"></i>
      {{ $t('pages.graph.backtooverview') }}
      <span v-if="userStore.isAuthenticated && blogStore.unvisitedPostCount" class="badge ml-2">{{
        blogStore.unvisitedPostCount
      }}</span>
    </router-link>

    <div class="pricingTitle">
      <h3>
        <utility-icon util="fuel"></utility-icon>
        <span>{{ graphTitle }}</span>
      </h3>
    </div>
    <settings-modal ref="settingsModal" :title="$t('general.filters')">
      <div class="row">
        <div class="col-md-4">
          <label for="utility">{{ $t('pages.graph.product') }}</label>
          <default-multiselect
            id="utility"
            label="name"
            trackBy="value"
            :options="fuelTypeStore.translatedFuelTypes"
            v-model="searchParameters.utilitytype"
            @update:model-value="utilityChanged"
          >
          </default-multiselect>
        </div>
        <div class="col-md-4">
          <label for="startDate">
            {{ $t('pages.graph.start') }}
          </label>
          <default-datetime
            v-model="startDate"
            @update:model-value="updateSeries"
            id="startDate"
            :max="endDate"
          />
        </div>
        <div class="col-md-4">
          <label for="endDate">
            {{ $t('pages.graph.stop') }}
          </label>
          <default-datetime
            @update:model-value="updateSeries"
            v-model="endDate"
            id="endDate"
            :max="now"
          />
        </div>

        <div class="col-md-12" :class="fastSearchClass">
          <button class="btn btn-light" @click="setPeriodByYearAgo(0)">
            {{ this.getPeriodTranslation(0) }}
          </button>
          <button class="btn btn-light" @click="setPeriodByYearAgo(1)">
            {{ this.getPeriodTranslation(1) }}
          </button>
          <button class="btn btn-light" @click="setPeriodByYearAgo(2)">
            {{ this.getPeriodTranslation(2) }}
          </button>
          <button class="btn btn-light" @click="setPeriodByYearAgo(3)">
            {{ this.getPeriodTranslation(3) }}
          </button>
          <button class="btn btn-light" @click="setPeriodByYearAgo(5)">
            {{ this.getPeriodTranslation(5) }}
          </button>
        </div>
      </div>
    </settings-modal>

    <settings-modal ref="exportModal" :title="$t('pages.graph.download')">
      <div class="row d-md-none">
        <div class="d-md-none col-md-4">
          <label>{{ $t('general.export-type') }}</label>
          <default-multiselect
            label="name"
            trackBy="value"
            :options="exportTypeStore.exportTypes"
            v-model="exportType"
          >
          </default-multiselect>
        </div>

        <div class="d-md-none col-md-4">
          <button class="btn btn-danger" type="button" @click="doExport">
            {{ $t('pages.graph.download') }}
          </button>
        </div>
      </div>
    </settings-modal>

    <div class="graph-buttons mb-3">
      <button @click="openSettings" class="btn btn-ghost">
        <i class="fas fa-sliders-h" aria-hidden="true"></i>
      </button>
      <button
        v-if="!device.isIOS() || !device.isPWA()"
        @click="openExportSettings"
        class="btn btn-ghost"
      >
        <i class="fas fa-download" aria-hidden="true"></i>
      </button>
    </div>

    <div v-if="!device.isIOS() || !device.isPWA()" class="dropdown">
      <button
        class="btn btn-ghost d-none d-lg-inline-block float-right dropdown-toggle mb-3"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {{ $t('pages.graph.download') }}
      </button>
      <div class="dropdown-menu">
        <a
          class="dropdown-item"
          v-for="type in exportTypeStore.exportTypes"
          :key="type.name"
          @click="exportTypeChosen(type)"
          style="cursor: pointer"
        >
          {{ formatter.name(type.name) }}
        </a>
      </div>
    </div>

    <!-- :key attribute is crucial for the export functionality. We basically force a re-render of the component on any change
             of the searchParameters.utilitytype.value variable located in the data section. If not used the this.$refs.chart
             object will not change as the component has not re-rendered after changing the utility type.-->
    <highcharts
      class="graph-container"
      :options="chartOptions"
      ref="graph"
      :key="shouldRerender"
    ></highcharts>
    <span v-if="fuelPriceStore.hasError.graphPrices" class="graph-error">
      <i class="fas fa-exclamation-triangle mr-2"></i
      >{{ $t('general.data_error') }}
    </span>
  </div>
</template>

<script>
import lodash from 'lodash'
import { DateTime } from 'luxon'
import { formatter } from '@/formatter'

import chartDefaults from '@/chartDefaults'
import chartDefaultsMobile from '@/chartDefaultsMobile'

import { useDevice } from '@/composables/device'
import { useAppInsights } from '@/composables/appInsights'

import { useMobileUtilityStore } from '@/stores/mobileUtility'
import { useFuelPriceStore } from '@/stores/fuelPrice'
import { usePriceCategoryStore } from '@/stores/priceCategory'
import { usePriceTypeStore } from '@/stores/priceType'
import { useFuelTypeStore } from '@/stores/fuelType'
import { useExportTypeStore } from '@/stores/exportType'
import { useUserStore } from '@/stores/user'
import { useCountryStore } from '@/stores/country'
import { useBlogStore } from '@/stores/blog'

import DefaultMultiselect from '@/components/common/DefaultMultiselect.vue'
import DefaultDatetime from '@/components/common/DefaultDatetime.vue'
import SettingsModal from '@/components/general/SettingsModal.vue'
import UtilityIcon from '@/components/common/UtilityIcon.vue'
import { inject } from 'vue'

export default {
  components: {
    DefaultMultiselect,
    DefaultDatetime,
    SettingsModal,
    UtilityIcon,
  },
  data() {
    const startDate = DateTime.now().startOf('day').minus({ months: 6 })
    const endDate = DateTime.now().startOf('day')

    return {
      startDate,
      endDate,
      now: DateTime.now(),
      country: '',
      shouldRerender: false,
      searchParameters: {
        utilitytype: {},
        country: 'eu',
        order: ['date:asc'],
        select: ['stripname', 'date', 'base'],
      },
      exportType: {
        name: 'pdf',
        value: 'application/pdf',
      },
    }
  },
  setup() {
    const device = useDevice()
    const appInsights = useAppInsights()

    const countryStore = useCountryStore()
    const exportTypeStore = useExportTypeStore()
    const fuelPriceStore = useFuelPriceStore()
    const fuelTypeStore = useFuelTypeStore()
    const mobileUtilityStore = useMobileUtilityStore()
    const priceCategoryStore = usePriceCategoryStore()
    const priceTypeStore = usePriceTypeStore()
    const userStore = useUserStore()
    const pdfExportHost = inject('pdf-export-host')
    const blogStore = useBlogStore()

    return {
      device,
      appInsights,
      countryStore,
      exportTypeStore,
      fuelPriceStore,
      fuelTypeStore,
      mobileUtilityStore,
      priceCategoryStore,
      priceTypeStore,
      fuelTypeStore,
      exportTypeStore,
      userStore,
      formatter,
      pdfExportHost,
      blogStore,
    }
  },
  computed: {
    graphTitle() {
      return this.searchParameters.utilitytype.name
    },
    optionsMobile() {
      if (this.device.isMobile()) {
        return chartDefaultsMobile
      }

      return chartDefaults
    },
    chartOptions() {
      const __scope = this
      return {
        ...chartDefaults,
        colors: chartDefaults.utilityColors.fuel,
        chart: {
          ...chartDefaults.chart,
          height: this.device.isMobile() ? null : chartDefaults.chart.height,
        },
        title: {
          text: this.graphTitle,
        },
        series: this.series,
        yAxis: {
          ...chartDefaults.yAxis,
          title: {
            text: this.yAxisLabel,
            x: this.device.isMobile() ? 0 : 3,
          },
          labels: {
            format: '{value}',
            style: {
              fontSize: '14px',
            },
            x: -5,
          },
        },
        tooltip: {
          formatter() {
            const dateLabel = DateTime.fromMillis(this.x).toFormat('d LLL yyyy')
            const priceLabel =
              __scope.$t('general.marketprice') +
              ' ' +
              __scope.pricingSymbol +
              this.y.toFixed(2).replace('.', ',')
            return (
              `<b>${this.point.name}</b>` +
              '<br>' +
              dateLabel +
              '<br>' +
              priceLabel
            )
          },
        },
        legend: {
          ...this.optionsMobile.legend,
        },
      }
    },
    pricingSymbol() {
      switch (this.searchParameters.utilitytype.key) {
        case 'oil':
          return '$'
        case 'coal':
          return '$'
        case 'co2':
          return '€'
        default:
          return ''
      }
    },
    yAxisLabel() {
      switch (this.searchParameters.utilitytype.key) {
        case 'oil':
          return this.pricingSymbol + '/' + this.$t('general.unit.barrel')
        case 'coal':
          return this.pricingSymbol + '/' + this.$t('general.unit.tone')
        case 'co2':
          return this.pricingSymbol + '/' + this.$t('general.unit.tone')
        default:
          return ''
      }
    },
    series() {
      const series = this.fuelPriceStore.graphPrices.reduce(
        (series, entry) => {
          series[0].data.push({
            x: DateTime.fromFormat(entry.date, 'yyyyLLdd').valueOf(),
            y: parseFloat(entry.base),
            name: entry.stripname,
            printInfo: {
              type: this.exportType,
              contact: this.userStore.contact,
              country: this.countryStore.country.code,
              host: this.pdfExportHost,
            },
          })
          return series
        },
        [
          {
            name: this.searchParameters.utilitytype.name,
            data: [],
          },
        ],
      )

      return Object.values(series)
    },
    fastSearchClass() {
      return this.device.isMobile()
        ? 'fast-search-buttons-mobile'
        : 'fast-search-buttons'
    },
  },
  methods: {
    setStartingParameters() {
      this.searchParameters.utilitytype = {
        name: this.$t(`general.utilitytype.${this.$route.params.util}`),
        value: '',
        key: this.$route.params.util,
        country: this.countryStore.country.code,
      }
      switch (this.$route.params.util) {
        case 'oil':
          this.searchParameters.utilitytype.value = 'Crude Oil'
          break
        case 'co2':
          this.searchParameters.utilitytype.value = 'C02 Emission'
          break
        default:
          this.searchParameters.utilitytype.value = 'Coal'
          break
      }

      this.updateSeries()
    },
    utilityChanged() {
      this.appInsights?.trackEvent(
        { name: 'FuelGraphUtilityChanged' },
        {
          utility: this.searchParameters.utilitytype.value,
        },
      )
      this.updateSeries()
    },
    onMobileUtilityChanged() {
      if (this.mobileUtilityStore.utility === 'fuel') {
        return
      }

      const nextRouteAreas =
        this.searchParameters.country === 'de' &&
        this.mobileUtilityStore.utility === 'gas'

      this.$router.push({
        name: nextRouteAreas
          ? 'FuturePriceGraphArea'
          : 'FuturePriceGraphProduct',
        params: {
          ...this.$route.params,
          util: this.mobileUtilityStore.shortUtility,
          area: nextRouteAreas ? 1 : -1,
        },
      })
    },
    openSettings() {
      this.$refs.settingsModal.show()
    },
    openExportSettings() {
      this.$refs.exportModal.show()
    },
    updateSeries: lodash.debounce(
      function () {
        this.fuelPriceStore.fetchGraphPrices({
          ...this.searchParameters,
          utilitytype: this.searchParameters.utilitytype.value,
          start: this.startDate.toFormat('yyyyLLdd'),
          end: this.endDate.toFormat('yyyyLLdd'),
        })
      },
      500,
      {
        trailing: true,
      },
    ),
    exportTypeChosen(exportType) {
      this.exportType = exportType
      this.doExport()
    },
    doExport() {
      // Make sure, the option is one of the 2 possible options (pdf, png)
      const exportTypeName = this.exportType.name
      const chosenType = this.exportTypeStore.exportTypes
        .filter(function (data) {
          return data.name === exportTypeName
        })
        .shift()

      if (chosenType.value !== undefined) {
        this.$refs.graph.chart.exportChart({
          type: chosenType.value,
          filename: `${this.graphTitle.replace(',', '-')}`,
          allowHTML: true,
          formAttributes: {
            target: '_blank',
          },
        }) // if you add this ', this.chartOptions' as 2nd argument the footer,header are not added and also the axis labels are changed!
      }
    },
    setPeriodByYearAgo(yearsAgo) {
      yearsAgo = parseInt(yearsAgo)
      this.startDate = DateTime.now().startOf('year').minus({ years: yearsAgo })
      this.endDate = DateTime.now().startOf('day')

      this.updateSeries()
    },
    getPeriodTranslation(yearsAgo) {
      if (this.device.isMobile()) {
        return (
          this.$t('general.initials-years-ago') +
          (parseInt(yearsAgo) === 0 ? '' : '-' + yearsAgo)
        )
      } else {
        return (
          '1 ' +
          this.$t('general.months.1.long') +
          ' ' +
          (parseInt(yearsAgo) === 0
            ? DateTime.now().startOf('year')
            : DateTime.now()
                .startOf('year')
                .minus({ years: parseInt(yearsAgo) })
          ).toFormat('yyyy') +
          ' - ' +
          this.$t('general.present')
        )
      }
    },
  },
  mounted() {
    this.setStartingParameters()
  },
  watch: {
    searchParameters: {
      handler() {
        this.shouldRerender = !this.shouldRerender
      },
      deep: true,
    },
    $route(to) {
      this.setStartingParameters()
    },
    'device.deviceType': function () {
      if (this.device.isMobile()) {
        this.$refs.graph.chart.setSize(null, null)
        return
      }

      this.$refs.graph.chart.setSize(null, chartDefaults.chart.height)
    },
    'mobileUtilityStore.utility': function () {
      this.onMobileUtilityChanged()
    },
    'fuelPriceStore.isLoading.graphPrices': function (isLoading) {
      if (isLoading) {
        this.$refs.graph.chart.showLoading()

        return
      }

      this.$refs.graph.chart.hideLoading()
    },
  },
}
</script>

<style scoped lang="scss">
.fast-search-buttons {
  margin-top: 2%;
  button {
    margin-top: 10px;
    margin-right: 20px;
    font-size: 12px;
  }
}

.fast-search-buttons-mobile {
  button {
    height: 32px;
    padding-top: 8px;
    font-size: 11px;
    margin-right: 10px;
    margin-top: 10px;
  }
}
</style>
