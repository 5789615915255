import { defineStore } from 'pinia'
import { computed, onMounted, ref } from 'vue'
import { useCountryStore } from '@/stores/country'
import axios from '@/axios'

export const useUserStore = defineStore('user', (force = false) => {
  let isChecked = false

  const isAuthenticated = ref(false)
  const area = ref(-1)
  const contact = ref(null)
  const alertsEnabled = ref(false)
  const cmsEnabled = ref(false)
  const isGuest = computed(() => !isAuthenticated.value)

  onMounted(() => {
    const countryStore = useCountryStore()

    if (isChecked && !force) {
      return
    }

    axios
      .get('/api/auth/check')
      .then((response) => {
        if (!response.data.authenticated) {
          isAuthenticated.value = false
          area.value = -1
          contact.value = null
          alertsEnabled.value = false
          cmsEnabled.value = false

          countryStore.setDefaultCountry()

          return
        }

        const currentUser = response.data.currentUser

        isAuthenticated.value = true
        area.value = currentUser.area
        contact.value = currentUser.contact
        alertsEnabled.value = currentUser.alertsEnabled
        cmsEnabled.value = currentUser.cmsEnabled

        countryStore.setDefaultCountry(currentUser.country.toLowerCase())
      })
      .catch((e) => {
        console.error(e)
        isAuthenticated.value = true
      })
      .finally(() => {
        isChecked = true
      })
  })

  return {
    isAuthenticated,
    isGuest,
    area,
    contact,
    alertsEnabled,
    cmsEnabled,
  }
})
