<template>
  <div>
    <router-link
      :to="{ name: 'Home' }"
      class="btn btn-default px-1 d-none d-md-inline-block float-right"
    >
      <i class="fas fa-arrow-circle-left"></i>
      {{ $t('pages.graph.backtooverview') }}
      <span v-if="userStore?.isAuthenticated && blogStore.unvisitedPostCount" class="badge ml-2">{{
        blogStore.unvisitedPostCount
      }}</span>
    </router-link>

    <div class="fixed-mobileheader">
      <div class="pricingTitle">
        <h3>
          <utility-icon :util="util"></utility-icon>

          <span>{{ marketName }} {{ utilName }}</span>

          <select
            v-if="hasAreas"
            v-model="searchParameters.area"
            @change="areaChanged"
            class="form-control sizeSelectBox"
          >
            <template v-for="area in areaStore.areas">
              <option :value="area.value">{{ area.name }}</option>
            </template>
          </select>
        </h3>
      </div>

      <div class="graph-buttons">
        <button @click="openSettings" class="btn btn-ghost">
          <i class="fas fa-sliders-h" aria-hidden="true"></i>
        </button>
      </div>
    </div>

    <settings-modal ref="settingsModal" :title="$t('general.filters')">
      <div class="row mb-3">
        <div class="col-md-4">
          <label for="date">
            {{ $t('pages.table.date') }}
          </label>
          <default-datetime
            v-model="date"
            @update:model-value="dateChanged"
            id="date"
            :max="now"
          />
        </div>
      </div>
    </settings-modal>

    <loadable-table
      :loading="futurePriceStore.isLoading.matrixPrices"
      :error="futurePriceStore.hasError.matrixPrices"
      :columns="10"
      :expected-rows="20"
      :sticky-head="true"
      table-class="table table-striped"
      :items="futurePriceStore.matrixPrices"
      :key="updateTable"
    >
      <!-- Table headers -->
      <template #head>
        <thead>
          <tr>
            <th scope="col">{{ $t('component.futuretable.product') }}</th>
            <th scope="col" v-if="$route.params.util === 'g'">&nbsp;</th>
            <th
              scope="col"
              v-if="convertsPrice"
              v-tooltip:top.html="tooltips.base"
            >
              {{ $t('component.futuretable.basem3') }}
              <i class="fas fa-info-circle"></i>
            </th>
            <th scope="col" v-tooltip:top.html="tooltips.base" v-else>
              {{ $t('component.futuretable.basemw') }}
              <i class="fas fa-info-circle"></i>
            </th>
            <th scope="col">{{ $t('component.futuretable.basediff') }}</th>
            <th scope="col" v-if="!device.isMobile()">
              {{ $t('component.futuretable.basepercentage') }}
            </th>
            <th scope="col" v-if="$route.params.util === 'g'">&nbsp;</th>
            <th
              scope="col"
              v-tooltip:top.html="tooltips.peak"
              v-if="util === 'e'"
            >
              {{ $t('component.futuretable.peak') }}
              <i class="fas fa-info-circle"></i>
            </th>
            <th scope="col" v-if="util === 'e'">
              {{ $t('component.futuretable.offpeak') }}
            </th>
            <th scope="col" v-if="!device.isMobile()">
              {{ $t('component.futuretable.min') }}
            </th>
            <th scope="col" v-if="!device.isMobile()">
              {{ $t('component.futuretable.max') }}
            </th>
            <th scope="col" v-if="futurePriceStore.matrixPrices.length !== 0">
              {{
                DateTime.fromFormat(
                  futurePriceStore.matrixPrices[0].date,
                  'yyyyLLdd',
                ).toFormat('dd-LL')
              }}
            </th>
          </tr>
        </thead>
      </template>

      <!-- Message if no prices are available -->
      <tr v-if="futurePriceStore.matrixPrices.length === 0">
        <td
          class="selected text-center"
          :colspan="searchParameters.utilitytype === 'Electricity' ? 8 : 8"
        >
          {{ $t('pages.table.no results') }}
        </td>
      </tr>

      <!-- Price rows -->
      <template #row="{ item }">
        <td :class="otcPriceClass(item)">{{ item.stripname }}</td>
        <td v-if="util === 'g'">&nbsp;</td>
        <td>
          {{
            convertsPrice
              ? formatter.volume(item.base)
              : formatter.decimal(item.base)
          }}
        </td>

        <td>
          <indicator-icon :new="item.base" :old="item.base_prev" />
          {{ formatter.decimal(item.base_diff) }}
        </td>
        <td v-if="!device.isMobile()">
          {{ formatter.decimal(item.base_percentage) }} %
        </td>

        <td v-if="util === 'g'">&nbsp;</td>
        <td v-if="util === 'e'">
          {{
            convertsPrice
              ? formatter.volume(item.peak)
              : formatter.decimal(item.peak)
          }}
        </td>
        <td v-if="util === 'e'">
          {{
            convertsPrice
              ? formatter.volume(item.offpeak)
              : formatter.decimal(item.offpeak)
          }}
        </td>

        <template v-if="item.stripmin && !device.isMobile()">
          <td>
            {{
              convertsPrice
                ? formatter.volume(item.stripmin)
                : formatter.decimal(item.stripmin)
            }}
          </td>
          <td>
            {{
              convertsPrice
                ? formatter.volume(item.stripmax)
                : formatter.decimal(item.stripmax)
            }}
          </td>
        </template>
        <template v-else-if="!device.isMobile()">
          <td>-</td>
          <td>-</td>
        </template>

        <td>
          <router-link
            :to="{
              name: hasAreas
                ? 'FuturePriceGraphArea'
                : 'FuturePriceGraphProduct',
              params: {
                area: hasAreas ? searchParameters.area : null,
                product: item.stripname,
                util,
              },
            }"
          >
            <i class="fas fa-chart-line" aria-hidden="true"></i>
          </router-link>
        </td>
      </template>
    </loadable-table>
    <div
      class="otc-years-description"
      v-if="hasOTCPrices"
      v-html="$t('component.futuretable.otc-clarification')"
    ></div>
    <div v-if="hasMoreProducts" class="loadMorePrices" @click="toggleProducts">
      {{ $t(this.loadMessage) }}
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { formatter } from '@/formatter'

import { END_DATE_NCG_GPL_MARKETS } from '@/contstants'

import { useAppInsights } from '@/composables/appInsights'
import { useDevice } from '@/composables/device'

import { useCountryStore } from '@/stores/country'
import { useAreaStore } from '@/stores/area'
import { useFuturePriceStore } from '@/stores/futurePrice'
import { useMobileUtilityStore } from '@/stores/mobileUtility'
import { usePriceTypeStore } from '@/stores/priceType'
import { useBlogStore } from '@/stores/blog'

import DefaultDatetime from '@/components/common/DefaultDatetime.vue'
import IndicatorIcon from '@/components/common/IndicatorIcon.vue'
import UtilityIcon from '@/components/common/UtilityIcon.vue'
import LoadableTable from '@/components/common/LoadableTable.vue'
import SettingsModal from '@/components/general/SettingsModal.vue'

export default {
  components: {
    DefaultDatetime,
    IndicatorIcon,
    UtilityIcon,
    LoadableTable,
    SettingsModal,
  },
  data() {
    return {
      date: null,
      now: DateTime.now(),
      searchPhrase: '',
      priceCompare: '',
      option: {},
      availableAreas: [],
      updateTable: false,
      searchParameters: {
        utilitytype: null,
        period: ['Y', 'Q'],
        area: null,
      },
      visibleQuartilesNumber: 8,
    }
  },
  setup() {
    const appInsights = useAppInsights()
    const device = useDevice()
    const mobileUtilityStore = useMobileUtilityStore()
    const areaStore = useAreaStore()
    const futurePriceStore = useFuturePriceStore()
    const priceTypeStore = usePriceTypeStore()
    const countryStore = useCountryStore()
    const blogStore = useBlogStore()

    return {
      appInsights,
      device,
      countryStore,
      mobileUtilityStore,
      areaStore,
      futurePriceStore,
      priceTypeStore,
      DateTime,
      formatter,
      blogStore,
    }
  },
  computed: {
    util() {
      return this.$route.params.util
    },
    tooltips() {
      return {
        base: this.$t(
          'general.marketNames.' +
            this.util +
            '.' +
            this.countryStore.country.code +
            (this.util === 'g' ? '.future' : '.future.base'),
        ),
        peak: this.$t(
          'general.marketNames.' +
            this.util +
            '.' +
            this.countryStore.country.code +
            '.future.peak',
        ),
      }
    },
    marketName() {
      if (!this.countryStore.country.code) {
        return ''
      }

      return (
        this.$t('general.market.future') +
        ' ' +
        this.countryStore.country.code.toUpperCase()
      )
    },
    utilName() {
      return this.$t('general.utilitytype.' + this.util)
    },
    hasAreas() {
      return (
        (this.countryStore.country.code === 'de' ||
          this.countryStore.country.code === 'at') &&
        this.searchParameters.utilitytype === 'Natural Gas'
      )
    },
    hasMoreProducts() {
      // Show the message 'load more/less' only if there are prices and more products to show.
      const nonYearlyProducts = this.futurePriceStore.matrixPrices.filter(
        (item) => {
          return !item.stripname.includes('Cal')
        },
      )

      return (
        this.futurePriceStore.matrixPrices.length !== 0 &&
        nonYearlyProducts.length > this.visibleQuartilesNumber
      )
    },
    convertsPrice() {
      return (
        this.countryStore.country.code === 'nl' &&
        this.searchParameters.utilitytype === 'Natural Gas'
      )
    },
    marketCode() {
      if (
        this.countryStore.country.code === 'nl' ||
        this.countryStore.country.code === 'be'
      ) {
        return 'endex'
      }

      if (this.countryStore.country.code === 'de') {
        return 'eex'
      }

      return ''
    },
    nonOTCQuartiles() {
      return this.nextQuartiles(4)
    },
    visibleQuartiles() {
      // Show near future price rows only. Add property for showing/hiding the row
      return this.nextQuartiles(this.visibleQuartilesNumber)
    },
    hasOTCPrices() {
      return (
        this.futurePriceStore.matrixPrices.filter((item) => {
          item.isShown =
            item.stripname.includes('Cal') ||
            this.visibleQuartiles.includes(item.stripname)
          return this.isOTCPrice(item)
        }).length > 0
      )
    },
    loadMessage() {
      return this.updateTable ? 'general.load-less' : 'general.load-more'
    },
  },
  methods: {
    setStartingParameters() {
      const savedCountry = localStorage.getItem('selectedCountry')

      this.countryStore.country.code =
        savedCountry || this.countryStore.country.code

      this.date = this.$route.query.dateSetter
        ? DateTime.fromFormat(this.$route.query.dateSetter, 'yyyyLLdd')
        : DateTime.now().startOf('day').minus({ days: 1 })

      this.setAreas()
      this.priceTypeStore.setPriceTypeByValue('Settlement')

      this.searchParameters = {
        ...this.searchParameters,
        utilitytype: this.util === 'e' ? 'Electricity' : 'Natural Gas',
        country: this.countryStore.country.code,
        pricetype: this.priceTypeStore.priceType,
        date: this.date.toFormat('yyyyLLdd'),
        area: this.getAreaByCountry(this.countryStore.country.code),
      }
      this.getIceMarketData()
    },
    setAreas() {
      this.availableAreas = this.areaStore.areas.filter((area) =>
        area.countries.includes(this.countryStore.country.code),
      )
    },
    getAreaByCountry(country) {
      if (this.util === 'g' && country === 'de') {
        return this.startDate < END_DATE_NCG_GPL_MARKETS
          ? (this.searchParameters.area ??
              this.areaStore.areas.find((area) => {
                return area.name === 'NCG'
              })?.value)
          : this.areaStore.areas.find((area) => {
              return area.isDefaultInCountries.includes(country)
            })?.value
      } else {
        return '-1'
      }
    },
    onCountryChanged() {
      localStorage.setItem('selectedCountry', this.countryStore.country.code)

      this.searchParameters.country = this.countryStore.country.code
      this.searchParameters.area = this.getAreaByCountry(
        this.countryStore.country.code,
      )
      this.setAreas()

      this.getIceMarketData()
    },
    onMobileUtilityChanged() {
      if (!this.device.isMobile()) {
        return
      }

      const nextRouteAreas =
        this.countryStore.country.code === 'de' &&
        this.mobileUtilityStore.utility === 'gas'

      this.setAreas()

      this.$router.push({
        name: nextRouteAreas ? 'FuturePriceMatrixArea' : 'FuturePriceMatrix',
        params: {
          ...this.$route.params,
          util: this.mobileUtilityStore.shortUtility,
          area: nextRouteAreas ? '1' : '-1',
        },
      })
    },
    onPriceTypeChanged() {
      this.getIceMarketData()
    },
    dateChanged() {
      this.setAreas()
      this.searchParameters.area = this.getAreaByCountry(
        this.countryStore.country.code,
      )
      this.appInsights?.trackEvent(
        { name: 'FuturePricePage-DateChanged' },
        { date: this.date.toFormat('yyyyLLdd') },
      )
      this.getIceMarketData()
    },
    areaChanged() {
      this.appInsights?.trackEvent(
        { name: 'FuturePricePage-AreaChanged' },
        {
          area: this.searchParameters.area,
        },
      )
      this.getIceMarketData()
    },
    getIceMarketData() {
      this.updateTable = false
      const parameters = {
        ...this.searchParameters,
        date: this.date.toFormat('yyyyLLdd'),
        pricetype: this.priceTypeStore.priceType.value,
        utilitytype: this.util === 'e' ? 'Electricity' : 'Natural Gas',
        area: this.hasAreas ? this.searchParameters.area : '-1',
        country:
          this.hasAreas && this.searchParameters.area === '3'
            ? 'DE'
            : this.countryStore.country.code.toUpperCase(),
        start: this.date.minus({ weeks: 1 }).toFormat('yyyyLLdd'),
      }

      this.futurePriceStore.fetchMatrixPrices(parameters)
    },
    openSettings() {
      this.$refs.settingsModal.show()
    },
    isOTCPrice(item) {
      const [periodType, year] = item.stripname.split('-')
      const currentYear = parseInt(DateTime.now().toFormat('yy'))

      if (periodType === 'Cal') {
        return (
          currentYear + 4 === parseInt(year) ||
          currentYear + 5 === parseInt(year) ||
          currentYear + 6 === parseInt(year) ||
          currentYear + 7 === parseInt(year)
        )
      } else if (periodType.charAt(0) === 'Q') {
        return !this.nonOTCQuartiles.includes(item.stripname)
      }
      return false
    },
    otcPriceClass(item) {
      return this.isOTCPrice(item) ? 'otc-price' : ''
    },
    nextQuartiles(quartileAmount) {
      return this.futurePriceStore.matrixPrices
        .filter((item) => {
          const [periodType, year] = item.stripname.split('-')
          return periodType.charAt(0) === 'Q'
        })
        .slice(0, quartileAmount)
        .map((item) => {
          return item.stripname
        })
    },
    /*
    / Toggles visibility of products which are N (this.visibleQuartilesNumber) quartiles ahead from now.
    */
    toggleProducts() {
      // The variable 'updateTable' is a key of the loadable table so that the table rerenders if this variable updates.
      this.updateTable = !this.updateTable
      this.futurePriceStore.matrixPrices.map((item) => {
        // If the product is a Cal product, dont toggle. Also if product after 'this.visibleQuartilesNumber' quartiles from now dont toggle
        if (
          !item.stripname.includes('Cal') &&
          !this.nextQuartiles(this.visibleQuartilesNumber).includes(
            item.stripname,
          )
        ) {
          item.isShown = this.updateTable
        }
      })
    },
  },
  mounted() {
    this.setStartingParameters()
  },
  watch: {
    $route(to) {
      this.setStartingParameters()
    },
    'mobileUtilityStore.utility': function () {
      this.onMobileUtilityChanged()
    },
    'countryStore.country': function () {
      if (this.countryStore.initialized) {
        localStorage.setItem('selectedCountry', this.countryStore.country.code)
        this.onCountryChanged()
      }
    },
    'priceTypeStore.priceType': function () {
      if (this.priceTypeStore.initialized) {
        this.onPriceTypeChanged()
      }
    },
  },
}
</script>

<style scoped lang="scss">
.otc-price {
  &:after {
    content: '¹';
  }
}

.otc-years-description {
  font-size: 11px;
  color: #adb5bd;
  margin-bottom: 15px;
}

.loadMorePrices {
  cursor: pointer;
  text-align: center;
}
</style>
