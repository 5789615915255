import { trans } from 'laravel-vue-i18n'
import { DateTime, Duration, Interval } from 'luxon'

export default {
  credits: {
    enabled: false,
  },
  utilityColors: {
    e: [
      '#e6194B',
      '#3cb44b',
      '#ffe119',
      '#4363d8',
      '#f58231',
      '#911eb4',
      '#42d4f4',
      '#f032e6',
      '#bfef45',
      '#fabebe',
      '#469990',
      '#e6beff',
      '#9A6324',
      '#fffac8',
      '#800000',
      '#aaffc3',
      '#808000',
      '#ffd8b1',
      '#000075',
      '#a9a9a9',
      '#000000',
    ],
    g: [
      '#4363d8',
      '#e6194B',
      '#3cb44b',
      '#ffe119',
      '#f58231',
      '#911eb4',
      '#42d4f4',
      '#f032e6',
      '#bfef45',
      '#fabebe',
      '#469990',
      '#e6beff',
      '#9A6324',
      '#fffac8',
      '#800000',
      '#aaffc3',
      '#808000',
      '#ffd8b1',
      '#000075',
      '#a9a9a9',
      '#000000',
    ],
    fuel: ['#F7BA40'],
  },
  colors: [],
  chart: {
    style: {
      fontFamily: 'myriad-pro, sans-serif',
      fontSize: '14px',
    },
    type: 'line',
    height: 400,
    margin: [10, 10, 65, 55],
    events: {
      load: function () {
        this.renderer
          .text(trans('component.chart.watermark'), 60, 200)
          .attr({
            x: '50%',
            y: '50%',
            class: 'watermark',
          })
          .add()
      },
    },
  },
  plotOptions: {
    line: {
      animation: false,
      marker: {
        enabled: false,
      },
    },
    series: {
      turboThreshold: 2000,
    },
  },
  xAxis: {
    type: 'datetime',
    gridLineWidth: 1,
    tickPositioner: function () {
      const duration = Duration.fromObject({
        milliseconds: this.dataMax - this.dataMin,
      })
      const positions = []
      const info = this.tickPositions.info
      let interval

      let periodBetween = 0
      if (duration.as('days') < 45) {
        interval = 'days'
        periodBetween = Math.ceil(duration.as('days'))
      } else if (duration.as('months') < 15) {
        interval = 'months'
        info.unitName = 'month'
        periodBetween = Math.ceil(duration.as('months'))
      } else {
        interval = 'years'
        info.unitName = 'year'
        periodBetween = Math.ceil(duration.as('years'))
      }

      if (this.dataMin) {
        let current = DateTime.fromMillis(this.dataMin).startOf(info.unitName)
        positions.push(current.startOf(info.unitName))
        for (let i = 1; i <= periodBetween + 1; i++) {
          current = current.plus({ [interval]: 1 }).startOf(info.unitName)
          positions.push(current)
        }
      }
      positions.info = info

      return positions
    },
    labels: {
      formatter() {
        const date = DateTime.fromISO(this.value)
        switch (this.dateTimeLabelFormat) {
          case 'week':
            return date.toFormat('d-L')
          case 'day':
            return date.toFormat('d LLL')
          case 'month':
            return date.toFormat('LLL yyyy')
          case 'year':
            return date.toFormat('yyyy')
        }
      },
      style: {
        fontSize: '14px',
      },
    },
    dateTimeLabelFormats: {
      day: 'day',
      week: 'week',
      month: 'month',
      year: 'year',
    },
  },
  legend: {
    itemStyle: {
      fontSize: '14px',
    },
    y: 20,
  },
  navigation: {
    buttonOptions: {
      enabled: false,
    },
  },
  exporting: {
    fallbackToExportServer: false,
    chartOptions: {
      title: {
        align: 'left',
        x: 50,
        y: -8,
        style: {
          fontSize: '8px',
          fontWeight: 'bold',
        },
      },
      plotOptions: {
        series: {
          lineWidth: 1,
        },
      },
      xAxis: {
        labels: {
          style: {
            fontSize: '8px',
            letterSpacing: '0px',
          },
        },
      },
      yAxis: {
        labels: {
          style: {
            fontSize: '8px',
            letterSpacing: '-1px',
          },
        },
        tickPositioner: function () {
          const positions = []
          const info = this.tickPositions.info
          const maxTicks = 10 // Maximum number of ticks on y Axis

          // Calculate tick spacing, start and end of ticks
          const range = Math.ceil(this.dataMax - this.dataMin)
          const tickSpacing = Math.ceil(range / (maxTicks - 1))
          const min = Math.floor(this.dataMin / tickSpacing) * tickSpacing
          const max = Math.ceil(this.dataMax / tickSpacing) * tickSpacing

          // Set initial y value for first tick
          let interval = min
          if (interval < 0) interval = 0

          // Add tick and increment until value reached maximum tick value
          while (interval <= max) {
            positions.push(interval)
            interval += tickSpacing
          }
          positions.info = info

          return positions
        },
      },
      legend: {
        itemStyle: {
          fontSize: '8px',
        },
      },
      chart: {
        events: {
          load: function () {
            const printInfo = this.series[0].data[0].printInfo

            this.renderer.setStyle({
              paddingBottom: 30,
              paddingTop: 50,
              height: 320,
              position: 'absolute',
            })

            // Watermark
            this.renderer
              .text(trans('component.chart.watermark'), 75, 200)
              .attr({
                opacity: '1',
              })
              .css({
                fontFamily: 'myriad-pro, sans-serif',
                fontSize: 45,
                color: '#eee',
              })
              .add()

            // Header
            this.renderer
              .image(
                'https://marktupdate.scholt.com/images/sec-logo.png',
                230,
                -46,
                150,
                20,
              )
              .add()

            this.renderer
              .image(
                `https://marktupdate.scholt.com/images/${printInfo.country}.png`,
                625,
                -51,
                30,
                25,
              )
              .add()

            // Footer
            this.renderer
              .image(
                'https://marktupdate.scholt.com/images/scholtenergy_logo_icon_gray.jpg',
                295,
                393,
                43,
                41,
              )
              .add()

            this.renderer
              .text(
                printInfo.contact
                  ? printInfo.contact
                  : trans('component.chart.printfooter'),
                printInfo.contact ? 190 : 230,
                416,
              )
              .css({
                textAlign: 'center',
                color: '#000',
                fontFamily: 'verdana',
                fontSize: 6,
                letterSpacing: '0.5px',
              })
              .attr({
                zIndex: 4,
              })
              .add()
          },
        },
      },
    },
  },
}
