import { defineStore } from 'pinia'
import { ref, computed, onMounted, nextTick } from 'vue'
import { trans } from 'laravel-vue-i18n'

export const usePriceTypeStore = defineStore('price-type', () => {
  const priceType = ref({})
  const priceTypes = ref([
    {
      name: 'general.pricetypes.ask',
      value: 'Ask',
    },
    {
      name: 'general.pricetypes.settlement',
      value: 'Settlement',
    },
    {
      name: 'general.pricetypes.bid',
      value: 'Bid',
    },
  ])
  const initialized = ref(false)

  onMounted(() => {
    setPriceTypeByValue('Settlement')

    nextTick(() => {
      initialized.value = true
    })
  })

  function reset() {
    setPriceTypeByValue('Settlement')
  }

  const translatedPriceTypes = computed(() => {
    return priceTypes.value.map((type) => {
      return {
        name: trans(type.name),
        value: type.value,
      }
    })
  })

  function setPriceTypeByValue(value) {
    const newPriceType = priceTypes.value.find(
      (priceType) => priceType.value === value,
    )

    if (typeof newPriceType === 'undefined') {
      return
    }

    priceType.value = newPriceType
  }

  return {
    priceType,
    setPriceTypeByValue,
    priceTypes,
    translatedPriceTypes,
    initialized,
    reset,
  }
})
