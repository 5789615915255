<template>
  <div id="alert-container">
    <router-link
      :to="{ name: 'Home' }"
      class="btn btn-default px-1 d-none d-md-inline-block float-right"
    >
      <i class="fas fa-arrow-circle-left"></i>
      {{ $t('pages.alert.back-to-homepage') }}
    </router-link>

    <div class="row mb-4">
      <div class="col-6 col-md-3 pr-0">
        <h2>{{ $t('pages.alert.pagetitle') }}</h2>
      </div>
      <div class="offset-4 offset-md-0 col-2 pl-0">
        <router-link :to="{ name: 'AlertCreate' }" class="btn btn-primary mb-2">
          <div v-if="device.isMobile()">
            <i class="fas fa-plus"></i>
          </div>
          <div v-else>
            {{ $t('pages.alert.create-alert') }}
          </div>
        </router-link>
      </div>
    </div>

    <div class="row" v-if="alertStore.alerts">
      <div
        v-for="alert in alertStore.alerts"
        class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4"
      >
        <div class="card" style="margin-bottom: 2rem">
          <div class="row card-body">
            <div class="col-10 col-md-9">
              <div class="row">
                <div class="col-1">
                  <utility-icon
                    :util="alert.utility_type === 'Electricity' ? 'e' : 'g'"
                  ></utility-icon>
                </div>
                <div class="col-10">
                  <h5 class="card-title">
                    {{
                      alert.market_type === 'future' ? 'Future' : 'Day-ahead'
                    }}
                    {{ alert.country + ' ' }}
                    {{
                      (alert.country === 'DE' || alert.country === 'AT') &&
                      alert.utility_type === 'Natural Gas'
                        ? areaStore.areas.filter(
                            (area) => area.value === alert.area,
                          )[0].name
                        : ''
                    }}
                    {{ $t('general.price-categories.' + alert.price_category) }}
                    {{ ' ' + (alert.product ? alert.product : '') }}
                  </h5>
                </div>
              </div>
              <p class="alert-comment">{{ alert.comment }}</p>
            </div>
            <div class="col-1">
              <i
                v-if="alert.is_disabled === '1'"
                @mouseover="showAlertDisabledMessage"
                class="fas fa-bell-slash"
              ></i>
            </div>
            <div class="col-1">
              <router-link
                :to="{
                  name: 'AlertEdit',
                  params: { alert_id: alert.alert_id },
                }"
              >
                <i class="fa fa-edit"></i>
              </router-link>
            </div>
          </div>
          <div>
            <img
              :class="isGraphRefreshed(alert)"
              class="card-img-top"
              :src="alert.thumbnail"
              alt="No image found"
            />
            <router-link
              v-if="alert.refresh_graph"
              class="btn btn-primary refresh-button"
              :to="{ name: 'EditGraph', params: { alert_id: alert.alert_id } }"
            >
              <i style="color: black" class="fas fa-redo-alt"></i>
            </router-link>
          </div>
          <div class="card-body alert-card-bottom">
            <div class="float-left">
              <p class="card-text">
                {{ '€ ' + alert.price }}
                {{ $t('general.comparison.' + alert.trigger + '.symbol') }}
                {{ $t('general.comparison.' + alert.trigger + '.text') }}
              </p>
            </div>
            <div class="float-right">
              <p class="card-text">
                {{ $t('general.latest') }}
                {{ ': ' + alert.last_triggered }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <nav aria-label="navigation">
      <ul class="pagination">
        <li
          class="page-item"
          v-if="alertStore.pagination.prev_page_url !== null"
        >
          <a
            class="page-link"
            href="#"
            @click.prevent="paginateTo(alertStore.pagination.current_page - 1)"
          >
            {{ $t('general.prev') }}
          </a>
        </li>

        <li class="page-item" v-for="page in paginationData">
          <a class="page-link" href="#" @click.prevent="paginateTo(page)">{{
            page
          }}</a>
        </li>

        <li
          class="page-item"
          v-if="alertStore.pagination.next_page_url !== null"
        >
          <a
            class="page-link"
            href="#"
            @click.prevent="paginateTo(alertStore.pagination.current_page + 1)"
          >
            {{ $t('general.next') }}
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'

import { useDevice } from '@/composables/device'
import { useAlertStore } from '@/stores/alert'
import { useAreaStore } from '@/stores/area'

import UtilityIcon from '@/components/common/UtilityIcon.vue'

export default {
  components: {
    UtilityIcon,
  },
  data() {
    return {
      searchPhrase: '',
      pagination: {},
      alertDisabledMessage: 'This alert is disabled.',
    }
  },
  setup() {
    const alertStore = useAlertStore()
    const areaStore = useAreaStore()
    const device = useDevice()

    return {
      alertStore,
      areaStore,
      device,
    }
  },
  mounted() {
    this.getAlerts()
  },
  methods: {
    getAlerts(page = 1) {
      this.alertStore.fetchAlerts({ order: ['created_at:desc'], page })
    },
    onAlertDisabled(alertKey) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-danger mr-2',
          cancelButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })

      swalWithBootstrapButtons
        .fire({
          title: this.$t('pages.alert.disable-window-title'),
          text: this.$t('pages.alert.disable-window-message', {
            item: 'alert',
          }),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('pages.alert.disable-window-confirm'),
          cancelButtonText: this.$t('general.cancel'),
        })
        .then((result) => {
          if (result.value) {
            this.alertStore.updateAlert(alertKey).then(() => {
              Swal.fire(
                this.$t('pages.alert.disable-alert-succeeded-title', {}, 'en'),
                this.$t(
                  'pages.alert.disable-alert-succeeded-message',
                  {},
                  'en',
                ),
                'success',
              ).then(() => {
                this.getAlerts() // add this.pagination.current_page
              })
            })
          }
        })
    },
    showAlertDisabledMessage() {
      return this.alertDisabledMessage
    },
    isGraphRefreshed(alert) {
      return alert.refresh_graph ? 'thumbnail-container' : ''
    },
    paginateTo(page) {
      this.alertStore.fetchAlerts(page)
      window.scrollTo(0, 0)
    },
  },
  computed: {
    paginationData() {
      if (this.limit === -1) {
        return 0
      }

      if (this.limit === 0) {
        return this.alertStore.pagination.last_page
      }

      const current = this.alertStore.pagination.current_page
      const last = this.alertStore.pagination.last_page
      const left = current
      const right = current + 1
      const range = []
      const pages = []
      let l

      for (let i = 1; i <= last; i++) {
        if (i === 1 || i === last || (i >= left && i < right)) {
          range.push(i)
        }
      }

      range.forEach(function (i) {
        if (l) {
          if (i - l === 2) {
            pages.push(l + 1)
          } else if (i - l !== 1) {
            pages.push('...')
          }
        }
        pages.push(i)
        l = i
      })

      return pages
    },
  },
}
</script>

<style scoped>
#alert-container {
  margin-top: 15px;
}

.alert-comment {
  font-size: 14px;
  color: lightgray;
}

.alert-card-bottom {
  background-color: #f7f7f7;
  font-size: 13px;
  font-style: italic;
}

.thumbnail-container {
  opacity: 0.3;
}

.refresh-button {
  position: absolute;
  top: 48%;
  left: 44%;
  border-radius: 5px;
}
</style>
